<template>
  <div>
    <TitleWrapper :filter-option="false" title="Phone" :display-breadcrumb="true" />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div class="mt-2 mb-6 w-full md:w-96">
        <PhoneNumber
          v-model="phoneNumber"
          title="Phone Number"
          :rules="'validNumber'"
          class="flex-1"
        />
      </div>
      <div class="mt-2 mb-6 w-full md:w-96">
        <PhoneNumber
          v-model="phoneNumber"
          title="Phone Number"
          :rules="'validNumber'"
          class="flex-1"
        />
      </div>
      <div class="md:inline-flex gap-2 text-sm rtl:flex-row-reverse">
        <div class="flex cursor-pointer items-center" @click="verifyPhone">
          <button
            class="mt-5 focus:outline-none focus:shadow-md font-normal md:ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
          >
            Change Phone
          </button>
        </div>
      </div>
      <verifyPhoneModal
        v-if="modalVerifyPhone"
        :modal="modalVerifyPhone"
        @toggle="verifyPhone"
      ></verifyPhoneModal>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import verifyPhoneModal from '@src/router/views/settings/security-settings/modals/verifyPhoneModal.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'

export default {
  components: {
    TitleWrapper,
    verifyPhoneModal,
    PhoneNumber,
  },
  data() {
    return {
      modalVerifyPhone: false,
      phoneNumber: '',
    }
  },
  methods: {
    verifyPhone() {
      this.modalVerifyPhone = !this.modalVerifyPhone
    },
  },
}
</script>
